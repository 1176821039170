.final-notes {
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
}

#sylius-coupon #sylius-save {
  background-color: transparent;
  color: black;
  border-radius: 0;
  border: 2px solid;
}

#sylius-coupon #sylius-save i {
  background-color: transparent;
}

.ui.header > .circular.cart.icon {
  display: none;
}

.cart-total {
  font-size: 25px;
}

.ui.circular.icon.button.sylius-cart-remove-button {
  background: transparent;
}

.ui.labeled.icon.button.continue, .ui.labeled.icon.button.checkout {
  margin-top: 10px;
  padding-left: 0!important;
  padding-right: 0!important;
  font-size: 25px;
  line-height: 0.5rem;
}

#sylius-cart-update {
  padding-left: 50px!important;
  padding-right: 50px!important;
  font-size: 25px;
  line-height: 0.5rem;
}

#sylius-cart-clear {
  padding: 15px 50px;
}

.ui.labeled.icon.button.checkout {
  background-color: #fdb913;
  color: white;
}

.ui.labeled.icon.button.checkout:hover {
  color: #212629;
}

#sylius-cart-items > thead > tr > th, #sylius-order > thead > tr > th {
  font-size: 25px;
  text-transform: uppercase;
  padding: 10px;
}

#sylius-shipping-address > .address-book-select, #sylius-billing-address > .address-book-select{
  padding: 16px 11px !important;
  font-size: 17px;
}

#sylius-shipping-address > h3.ui.dividing.header, #sylius-billing-address > h3.ui.dividing.header {
  font-size: 50px;
}

form[name="sylius_checkout_address"] .ui.large.icon.labeled.button {
  padding-top: 15px;
  padding-bottom: 15px;
}

form[name="sylius_checkout_address"] .ui.large.icon.labeled.button > i {
  background: none;
}

.payment-icons {
  display: flex;
}

.payment-icons > img.ui.small.image {
  align-self: center;
  max-width: 33%;
}

.payment-icons > img.ui.small.image:first-of-type {
  padding-right: 10px;
}

#sylius-shipping-methods .ui.segments, #sylius-payment-methods .ui.segments {
  box-shadow: none;
  border: none;
}

#sylius-shipping-methods div.description > p, #sylius-payment-methods div.description > p {
  font-size: 17px;
}

#sylius-shipping-methods .ui.dividing.header, #sylius-payment-methods .ui.dividing.header {
  font-size: 50px;
}
#sylius-shipping-methods a.header, #sylius-payment-methods a.header {
  font-size: 25px;
  text-transform: uppercase;
}

.ui.large.right.floated.fee.label {
  background: none;
  color: #212629;
  font-size: 25px;
}

#sylius-checkout-subtotal th {
  padding: 10px 0;
}

#sylius-shipments #sylius-shipping-method, #sylius-payments #sylius-payment-method {
  font-size: 25px;
}

#sylius-order #total {
  font-size: 25px;
}

form[name="sylius_checkout_complete"] .ui.huge.primary.fluid.icon.labeled.button {
  padding: 13px 0 !important;
  background-color: #fdb913;
  color: #ffffff;
}

form[name="sylius_checkout_complete"] .ui.huge.primary.fluid.icon.labeled.button:hover {
  color: #212629
}

table#sylius-order > tbody,
table#sylius-order > tbody > tr > td > .sylius-product-options > .item,
table#sylius-order > tbody > tr > td > .ui.header > .content > .sylius-product-name,
table#sylius-order > tbody > tr > td > .ui.header > .content > .sylius-product-variant-code {
  font-size: 17px;
}

#sylius-show-order {
  font-size: 25px;
  margin-bottom: 50px;
  padding: 0.785714em 150px;
  line-height: 0.5rem;
}

@media screen and (max-width: 480px) {
  #sylius_cart_promotionCoupon {
    max-width: 50%;
  }
}