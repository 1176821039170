.dropdown-toggle::after {
  display:none;
}

body > div > header > nav.navbar-dark {
  background-color: #212629;
  padding: 0;
}

.navbar-brand {
  margin-right: 45px;
  padding: 0;
}

.navbar-brand > img {
  max-height: 50px;
}

body > div > header > nav.navbar-dark > .navbar-nav > .nav-item > .nav-link {
  color: #fff;
  line-height: 30px;
  padding: 0 15px;
  font-size: 17px;
}

.locale-flag {
  max-height: 25px;
  margin-bottom: 3px;
  margin-left: 5px;
}

body > div > header > nav.navbar-dark > .navbar-nav > .item {
  color: #fff;
  line-height: 30px;
  padding: 0 15px;
  font-size: 17px;
}

body >  div > header > nav.navbar-dark > .navbar-nav > .nav-item > .nav-link:hover {
  color: #fdb913;
}

body > div > header > nav.navbar-light {
  background-color: #fff;
  border-bottom: 1px solid #838383;
  padding: 0 .1rem;
}

.dropdown > .dropdown-toggle:active {
  pointer-events: none;
}

body > div > header > nav.navbar-light > .navbar-collapse > .navbar-nav > .nav-item > .nav-link {
  color: #212629;
  font-size: 25px;
  font-weight: 800;
  line-height: 56px;
  padding: 0;
  border-bottom: 4px solid transparent;
  transition: all 0.4s;
}

body > div > header > nav.navbar-light > .navbar-collapse > .navbar-nav > .nav-item > .nav-link:hover,
body > div > header > nav.navbar-light > .navbar-collapse > .navbar-nav > .nav-item.show > .nav-link{
  color: #fdb913;
  border-bottom: 4px solid;
}

body > div > header > nav.navbar-light > .navbar-collapse > .navbar-nav > .nav-item > .nav-link.button.cart {
  font-size: 17px;
}

body > div >header > nav > div > .navbar-nav > .nav-item {
  position: static;
  height: 60px;
}

body > div >header > nav > div > .navbar-nav > .nav-item.nav-cart {
  position: relative;
}

.fa.fa-shopping-bag {
  padding-right: 5px;
}

.items-count {
  position: absolute;
  top: 30px;
  font-size: 12px;
  left: 10px;
  height: 15px;
  width: 15px;
  background: red;
  line-height: 15px;
  text-align: center;
  border-radius: 15px;
  color: white;
}

body > div > header > nav > div > .navbar-nav.navbar-central > .nav-item {
  padding: 0 20px;
}


body > div > header > nav > div > .navbar-nav.navbar-central {
  margin-left: auto;
}

body > div > header > nav > div > .navbar-nav.ml-auto {
  margin: 0;
  margin-right: 30px;
}

body > div > header > nav > div > .navbar-nav > .nav-item > .dropdown-menu {
  width: 100%;
  border: none;
  text-align: center;
  margin-top: 1px;
  padding: 20px 0;
}

.dropdown:hover>.dropdown-menu {
  display: block;
  margin: 0;
  border-radius: 0;
}

body > div > header > nav > div > .navbar-nav > .nav-item > .dropdown-menu > div {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  width: auto;
}

body > div > header > nav > div > .navbar-nav > .nav-item > .dropdown-menu > div > .product-list {
  list-style-type: none;
  padding-left: 50px;
}

body > div > header > nav > div > .navbar-nav > .nav-item > .dropdown-menu > div > .product-list > .product-item > a {
  font-size: 18px;
  font-weight: 800;
  line-height: 25px;
  color: #212629;
}

body > div > header > nav > div > .navbar-nav > .nav-item > .dropdown-menu > div > .product-list > .product-item > a:hover {
  color: #fdb913;
}

body > div > header > nav > div > .navbar-nav > .nav-item > .dropdown-menu > div > .dropdown-item {
  font-size: 17px;
  padding: 10px 50px;
  font-weight: 800;
}

body > div > header > nav > div > .navbar-nav > .nav-item > .dropdown-menu > div > .dropdown-item:hover {
  color: #fdb913;
  font-size: 17px;
  padding: 10px 50px;
}

.dropdown-item:hover {
  background-color: #fff;
}

.dropdown-item:active {
  background-color: #fff;
}

.navbar-dark > .navbar-nav {
  padding-right: 15px;
}

.navbar-dark > .navbar-nav .dropdown-menu {
  position: absolute;
}

@media screen and (min-width: 992px) and (max-width: 1280px) {
  body > div > header > nav > div > .navbar-nav > .nav-item > .dropdown-menu > div > .product-list > .product-item > a {
    font-size: 17px;
  }

  body > div > header > nav.navbar-light > .navbar-collapse > .navbar-nav > .nav-item > .nav-link {
    font-size: 17px;
  }
}

@media screen and (max-width: 991px) {
  .navbar-dark > .navbar-nav {
    flex-direction: row;
  }

  body > div > header > nav > div > .navbar-nav.navbar-central > .nav-item {
    position: relative;
    display: contents;
  }

  .dropdown > .dropdown-toggle:active {
    pointer-events: all;
  }

  body > div > header > nav > div > .navbar-nav > .nav-item > .dropdown-menu > div {
    width: 50%;
    float: left;
  }
}
