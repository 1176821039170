.ui.star.rating .icon {
  margin: auto;
}

.ui[class*="two column"].grid>.column.column-60 {
  width: 60%;
}

.ui[class*="two column"].grid>.column.column-40 {
  width: 40%
}

.breadcrumb {
  background-color: transparent;
  text-transform: uppercase;
  font-weight: 800;
  padding: 30px 0;
}

.ui.segment, .ui.attached.segment, .ui.tabular.menu, .ui.tabular.menu .item {
  border: none;
  box-shadow: none;
}

.ui.segment {
  padding-left: 0;
  padding-right: 0;
  font-size: 17px;
}

.ui.top.attached.menu>.item:first-child {
  padding-left: 0;
}

.product-price {
  text-align: right;
}

.ui.sub.header {
  font-size: 17px;
  font-weight: 800;
}

.ui.tabular.menu .item {
  font-size: 17px;
}

#sylius-product-name {
  font-size: 50px;
  margin-bottom: -15px;
  line-height: 40px;
}

#product-price {
  font-size: 25px;
}

.ui.form .field {
  margin: 30px 0;
}

.ui.labeled.icon.button.add-to-cart {
  font-size: 25px;
  width: 100%;
  border-radius: unset;
  background-color: #212629;
  line-height: 0.5rem;
  padding-left: 30px!important;
  padding-right: 30px!important;
  text-transform: uppercase;
  margin-top: 10px;
}

.ui.labeled.icon.button.add-to-cart:hover {
  color: #fdb913;
  background-color: #212629;
}

.ui.dividing.header {
  border-bottom: none;
}

#sylius-product-selecting-variant > .ui.form .field > label {
  width: 25%;
  float: left;
  margin-top: 10px;
  font-size: 17px;
  font-weight: 800;
  text-transform: uppercase;
}

#sylius-product-selecting-variant > .ui.form .field > select, #sylius-product-selecting-variant > .ui.form .field > input {
  height: 38px;
  border-width: 0 0 2px 0;
  border-radius: 0;
  border-color: #212629;
  width: 75%;
  text-transform: uppercase;
  font-size: 17px;
  padding: 0.1rem;
}

#sylius-product-selecting-variant > .ui.form .required.field>label:after {
  display: none;
}

.ui.container > .ui.horizontal.section.header {
  margin: 50px 0;
  text-align: center;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 800;
}

.ui.cards {
  margin-bottom: 0;
}

.ui.card, .ui.cards>.card {
  box-shadow: none;
  border-radius: 0;
}

.ui.card > a.image, .ui.cards > .card > a.image {
  background: transparent;
  border-radius: 0!important;
}

.ui.card > .content, .ui.cards > .card > .content {
  border-top: none;
}

.ui.card > .content > a, .ui.cards > .card > .content > a {
  text-align: center;
  font-size: 20px;
}

.ui.tabular.menu .item {
  padding-bottom: 0;
  font-weight: 800;
}

.ui.tabular.menu .item > span {
  padding-bottom: 1em;
  text-transform: uppercase;
}

.ui.tabular.menu .active.item > span {
  border-bottom: 3px solid #212629;
}

.sylius-product-price {
  text-align: center;
  font-weight: normal;
  font-size: 17px;
}

.ui.card>.content>.header:not(.ui), .ui.cards>.card>.content>.header:not(.ui) {
  font-size: 20px;
}

.ui.breadcrumb {
  font-size: 17px;
}

.ui.breadcrumb a {
  color: #212629;
}

.ui.breadcrumb a:hover {
  color: #212629;
}

.ui.breadcrumb div.active {
  color: #fdb913;
}

.ui.inline.dropdown>.text {
  font-size: 17px;
}

.level-up {
  float: right;
}

.ui.vertical.menu {
  border: none;
  box-shadow: none;
  font-size: 17px;
}

.ui.vertical.menu .item:before {
  content: none;
}

.ui.vertical.menu .item {
  padding-left: 0;
}

.ui.menu a.item:hover {
  background-color: transparent;
  color: #fdb913;
}

.ui.table {
  border-radius: 0;
}

.product-index {
  font-size: 25px;
  margin-left: 5px;
}

#product-gallery-carousel {
  padding-top: 0;
}

#product-gallery-carousel .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

#product-gallery-carousel .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23333' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.gallery .ui.image img:hover {
  cursor: pointer;
}

#main-image {
  max-width: 100%;
}

.realization > .time {
  line-height: 38px;
  font-size: 17px;
  font-weight: 800;
  text-transform: uppercase;
}

.realization > .time > .husar-yellow {
  line-height: 38px;
  font-size: 17px;
  font-weight: 800;
  text-transform: uppercase;
  color: #fdb913;
}

.realization > .distributor {
  float: right;
}

.realization > .distributor > a {
  font-size: 17px;
  font-weight: 800;
  text-transform: uppercase;
  color: #212629;
  line-height: 38px;
}

.realization > .distributor > a:hover {
  color: #fdb913;
}

.realization {
  margin-top: 30px;
}

#sylius-product-selecting-variant {
  padding-top: 0;
  margin-top: 30px;
}

#sylius-product-out-of-stock {
  font-size: 25px;
  width: 100%;
  border-radius: unset;
  background-color: rgba(0,0,0,.15);
  line-height: 0.5rem;
  padding: 0.785714em 30px!important;
  text-transform: uppercase;
  margin-top: 10px;
  box-shadow: none;
  color: #212629;
  font-weight: 700;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  #sylius-product-selecting-variant {
    padding-top: 0;
  }
  .gallery .ui.image {
    max-width: 50%;
    margin: 0;
  }
  #sylius-product-name {
    padding-bottom: 0!important;
  }
  .ui.stackable.grid > .wide.column.product-price {
    padding-top: 0!important;
    text-align: left;
  }
}