body,
.ui.menu,
.ui.header,
.ui.button,
.ui.card>.content>.header,
.ui.cards>.card>.content>.header,
button,
input,
optgroup,
select,
textarea {
  font-family: 'Saira Extra Condensed', sans-serif;
}

h1, h2, h3, h4, h5 {
  font-family: 'Saira Extra Condensed', sans-serif;
  font-weight: 800;
}

h1.ui.header, .ui.container h1.ui.header.register, .ui.container h1.ui.header.login {
  font-size: 50px;
  text-transform: uppercase;
}

h1.ui.header .sub.header {
  font-size: 17px;
  text-transform: uppercase;
}

h4.ui.header {
  font-size: 25px;
  text-transform: uppercase;
}

.ui.form .field>label, .ui.checkbox .box, .ui.checkbox label {
  font-size: 17px;
  text-transform: uppercase;
}

.pushable {
  height: 100%;
  overflow-x: hidden;
  padding: 0 !important;
}

.pushable > .pusher {
  position: relative;
  backface-visibility: hidden;
  overflow: hidden;
  min-height: 100%;
  z-index: 2;
}

.pusher > footer.ui.segment {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 200px;
  flex: 1;
}

.pusher > .container {
  padding-bottom: 200px;
}

body > div > header {
  width: 100%;
  position: fixed;
  z-index: 95;
}

body > div > .container {
  padding-top: 90px;
}

.carousel {
  max-width: 1600px;
  max-height: 900px;
  padding-top: 90px;
  margin: auto;
  overflow: hidden;
}

.carousel > .carousel-inner > .carousel-item > img {
  max-width: 100%;
  max-height: 100%;
}

.carousel > .carousel-inner > .carousel-item {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.carousel > .carousel-inner > .carousel-item > .carousel-caption {
  bottom: 50%;
  left: 50%;
  text-align: left;
  padding-left: 20px;
  top: 50%;
  transform: translateY(-50%);
  bottom: initial;
}

.carousel > .carousel-inner > .carousel-item > .carousel-caption > h1 {
  font-size: 70px;
  line-height: 60px;
}

.carousel > .carousel-inner > .carousel-item > .carousel-caption > p {
  font-size: 25px;
}

body > div > .blackout.show {
  height: 100%;
  position: fixed;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 50;
  transition: all 0.4s;
}

.ui.header {
  color: #212629;
}

.ui.inverted.segment,
.ui.primary.inverted.segment {
  background-color: #212629;
}

.homepage-blocks {
  display: block;
  margin: 50px auto;
  max-width: 1600px;
}

.homepage-block {
  display: inline-block;
}

.block-text {
  text-align: center;
  margin-bottom: 50px;
  padding: 0 20px;
}

.block-text > h1 {
  font-size: 50px;
  margin: 0;
}

.block-text > p {
  font-size: 25px;
}

.block-image {
  position: relative;
  text-align: center;
  max-width: 800px;
  max-height: 500px;
  float: left;
}

.block-image > img {
  height: 100%;
  width: 100%;
}

.image-caption {
  position: absolute;
  top: 15%;
  left: 10%;
  color: #fff;
  text-align: left;
  max-width: 30%;
}

.image-caption > h2 {
  font-size: 50px;
  line-height: 45px;
  margin: 0;
}

.image-caption > p {
  margin-top: 10px;
  font-size: 25px;
}

.carousel .btn.btn-primary, .homepage-blocks .btn.btn-primary {
  font-size: 25px;
  font-weight: 800;
  padding: 10px 20px;
  border-radius: 0;
  background-color: #212629;
  border: none;
  line-height: 25px;
  display: block;
  max-width: 200px;
}

.carousel .btn.btn-primary:hover, .homepage-blocks .btn.btn-primary:hover {
  color: #fdb913;
}

.carousel .btn.btn-primary:active:focus, .homepage-blocks .btn.btn-primary:active:focus {
  color: #fdb913;
  background-color: #212629;
  box-shadow: none;
}

.carousel .btn.btn-primary:focus, .homepage-blocks .btn.btn-primary:focus {
  box-shadow: none;
}

.ui.button {
  border-radius: 0;
  text-transform: uppercase;
}

.ui.message {
  border-radius: 0;
  font-size: 17px;
}

.ui.container > ui.header {
  margin-top: 14px;
}

.ui.container h1.ui.header.register, .ui.container h1.ui.header.login {
  margin-top: 14px;
}

.ui.form input:not([type]),
.ui.form input[type=date],
.ui.form input[type=datetime-local],
.ui.form input[type=email],
.ui.form input[type=file],
.ui.form input[type=number],
.ui.form input[type=password],
.ui.form input[type=search],
.ui.form input[type=tel],
.ui.form input[type=text],
.ui.form input[type=time],
.ui.form input[type=url],
.ui.form textarea {
  font-family: 'Saira Extra Condensed', sans-serif;
  font-size: 17px;
  border-radius: 0;
  border-width: 0 0 2px 0;
  border-color: #212629;
}


.ui.form input:not([type]):focus,
.ui.form input[type=date]:focus,
.ui.form input[type=datetime-local]:focus,
.ui.form input[type=email]:focus,
.ui.form input[type=file]:focus,
.ui.form input[type=number]:focus,
.ui.form input[type=password]:focus,
.ui.form input[type=search]:focus,
.ui.form input[type=tel]:focus,
.ui.form input[type=text]:focus,
.ui.form input[type=time]:focus,
.ui.form input[type=url]:focus,
.ui.form textarea:focus {
  border-radius: 0;
  border-color: #212629;
}

.ui.basic.button,
.ui.basic.buttons .button {
  border-radius: 0;
}

.ui.primary.button,
.ui.primary.buttons .button,
.ui.blue.button,
.ui.blue.buttons .button {
  background-color: #212629;
  text-transform: uppercase;
}

.ui.primary.button:hover,
.ui.primary.button:active,
.ui.primary.button:focus,
.ui.primary.buttons .button:hover,
.ui.primary.buttons .button:active,
.ui.primary.buttons .button:focus,
.ui.blue.button:hover,
.ui.blue.button:active,
.ui.blue.button:focus,
.ui.blue.buttons:hover .button:hover,
.ui.blue.buttons:hover .button:active,
.ui.blue.buttons:hover .button:focus {
  background-color: #212629;
  color: #fdb913;
}

.ui.green.button,
.ui.green.buttons .button {
  background-color: #fdb913;
}

.ui.labeled.icon.button.register {
  padding-left: 0!important;
  padding-right: 0!important;
}

.ui.green.button:hover,
.ui.green.button:active,
.ui.green.button:focus,
.ui.green.buttons .button:hover,
.ui.green.buttons .button:active,
.ui.green.buttons .button:focus {
  color: #212629;
  background-color: #fdb913;
}

.ui.toggle.checkbox input:checked~.box:before,
.ui.toggle.checkbox input:checked~label:before,
.ui.toggle.checkbox input:focus:checked~.box:before,
.ui.toggle.checkbox input:focus:checked~label:before {
  background-color: #fdb913!important;
}

.ui.buttons .button:first-child, .ui.buttons .button:last-child {
  border-radius: 0;
}

.ui.card>.content>a.header:hover, .ui.cards>.card>.content>a.header:hover {
  color: #fdb913;
}

.ui.segment.search {
  margin-bottom: 0;
  margin-top: 35px;
}

.ui.grid > .column.search-criteria {
  display: inline-flex;
}

.ui.grid > .search-criteria > .field {
  flex: 1;
  margin: 0;
}

.search-criteria > .ui.buttons {
  border-bottom: solid 2px #212629;
}

.search-criteria > .ui.buttons > .ui.labeled.icon.button {
  padding-left: 14px !important;
  padding-right: 14px !important;
  padding-top: 12px;
  padding-bottom: 10px;
  max-height: 36px;
}

.search-criteria > .ui.buttons > .ui.labeled.icon.button.negative {
  width: 38px;
  background-color: transparent;
}

.search-criteria > .ui.buttons > .ui.labeled.icon.button.negative > .cancel.icon {
  width: 38px;
  color: #e0e1e2;
  background-color: transparent;
}

.search-criteria > .ui.buttons > .ui.labeled.icon.button.negative > .cancel.icon:hover {
  color: #cacbcd
}

.ui.small.header {
  font-size: 17px;
}

.footer .ui.list>.item {
  padding: 0;
}

.footer .ui.list a {
  color: rgba(255,255,255,.5);
}

.footer .ui.list a:hover {
  color: #fff;
}

body.eupopup > .eupopup-container {
  text-transform: uppercase;
  font-size: 17px;
}

body.eupopup > .eupopup-container > .eupopup-buttons > .eupopup-button {
  font-size: 17px;
  color: #fdb913;
}

@media screen and (max-width: 1599px) {
  .homepage-block {
    display: block;
    margin: auto;
  }

  .block-image {
    float: none;
    margin: auto;
  }
}

@media screen and (max-width: 480px) {
  .pusher > .container {
    padding-bottom: 50px;
  }

  .image-caption {
    max-width: 100%;
  }

  .image-caption > h2 {
    font-size: 35px;
    margin: 0;
  }

  .image-caption > p {
    font-size: 20px;
  }

  .carousel .btn.btn-primary, .homepage-blocks .btn.btn-primary {
    font-size: 17px;
    line-height: 17px;
  }

  .carousel-control-next, .carousel-control-prev {
    top: 30%;
  }

  .carousel > .carousel-inner > .carousel-item > .carousel-caption {
    display: block!important;
  }

  .carousel > .carousel-inner > .carousel-item > .carousel-caption > h1 {
    font-size: 25px;
    line-height: 22px;
    margin-bottom: 5px;
  }

  .carousel > .carousel-inner > .carousel-item > .carousel-caption > p {
    font-size: 17px;
    line-height: 15px;
    margin-bottom: 5px;
  }

  .carousel-indicators {
    bottom: -5px;
  }
}