.container > .cms-content {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: justify;
}

.container > .cms-content > p {
  font-size: 17px;
}

.container > .cms-content > h1 {
  font-size: 50px;
}

.container > .cms-content > h2 {
  font-size: 25px;
}


