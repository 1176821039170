.distributors {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100vh;
  margin-bottom: 50px;
}

.distributors > .distributor {;
  display: block;
  width: 25%;
  font-size: 17px;
  margin-top: 30px;
}

.distributors > .distributor > .distributor-country {
  font-size: 50px;
  line-height: 40px;
  margin-bottom: 30px;
}

.distributors > .distributor > .distributor-name {
  font-weight: bold;
  margin-bottom: 15px;
}

@media screen and (max-height: 899px) {
  .distributors {
    height: 150vh;
  }
}

@media screen and (max-width: 480px) {
  .distributors {
    height: unset;
  }

  .distributors > .distributor {
    width: 100%;
  }
}